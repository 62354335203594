<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <el-select
          v-model="params.adminUserId"
          filterable
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="用户"
        >
          <el-option
            v-for="item in filUser"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <!-- <select-field
          :fieldsData="fieldsData"
          :originFeild="originFeild"
          :EchoField="sendField"
          @fieldChange="handleFieldChange"
        /> -->
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 10px"
          :loading="loading"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset"> 重置 </el-button>
      </div>
    </div>
    <!-- 表格数据 -->
    <el-table
      header-row-class-name="table-header"
      ref="userConsumeTable"
      class="consume-table"
      :data="dataList"
      style="width: 100%"
      height="650px"
      v-loading="loading"
      empty-text="暂无数据"
      :row-class-name="tableRowClassName"
      :span-method="objectSpanMethod"
      @sort-change="handleSortChange"
    >
      <el-table-column
        v-for="(item, index) in filFields"
        :key="index"
        :sortable="item.autoFields !== 0"
        :prop="item.fieldCode"
        :label="item.fieldName"
        :min-width="item.width + 'px' || '100px'"
      >
        <template slot-scope="scope">
          <div v-if="item.hasChild">
            <div
              v-for="(item2, index2) in filChildrenFieds(item.children)"
              :key="index2"
            >
              {{ item2.fieldName ? item2.fieldName + ":" : "" }}
              <span :style="'color:' + item.fontColor">{{
                scope.row[item2.fieldCode]
              }}</span>
            </div>
          </div>
          <span v-else :style="'color:' + item.fontColor">{{
            scope.row[item.fieldCode]
          }}</span>
        </template>
      </el-table-column>
      <template>
        <el-table-column
          v-for="(head, index3) in extraHeader"
          :key="index3 + 'extra'"
          :label="head"
          prop="head"
          min-width="180px"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.details[index3]">
              <div style="cursor: pointer; line-height: 20px">
                <div class="text">
                  单粉产值：<span class="primary-num">{{
                    scope.row.details[index3].fansPrice
                  }}</span>
                </div>
                <div class="text">
                  关注用户：<span class="remark-num">{{
                    scope.row.details[index3].subscribeUser
                  }}</span>
                </div>
                <div class="text">
                  留存率：<span class="green-num"
                    >{{ scope.row.details[index3].keepRate }}%</span
                  >
                </div>
                <div class="text">
                  付费数据：<span class="danger-num"
                    >{{ scope.row.details[index3].payNum }} </span
                  >/
                  <span class="success-num"
                    >{{ scope.row.details[index3].payRate }}%</span
                  >
                </div>
                <!-- <p>增：{{ scope.row.returnRateList[index3].addReturnRate }}%</p> -->
              </div>
            </div>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <!-- 公共抽屉 -->
    <common-drawer
      :showDrawer="showCommonDrawer"
      size="90%"
      @close="showCommonDrawer = false"
      :template="showTemplate"
      :nextParams="nextParams"
    />
    <!-- 分页 -->
    <el-pagination
      style="margin-top: 10px; text-align: right"
      background
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="20"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  platformList,
  yesterdayOptions,
  labelList,
  pickerOptions,
} from "@/assets/js/options";
import { formatThousandsDots } from "@/assets/js/utils";
// import ExcludeQuery from '@/components/excludeQuery'
// import SelectDeptUser from '@/components/SelectDeptUser'
// import SelectField from '@/components/SelectField'
// import SelectPlatformChannel from '@/components/SelectPlatformChannel'
import { getBookList } from "@/api/account";
import { getOperationStatics, getFields } from "@/api/statics";

import { mapActions, mapGetters } from "vuex";
export default {
  name: "intervalChannel",
  props: {
    lastParams: Object,
  },
  components: {
    // ExcludeQuery,
    // SelectDeptUser,
  },
  computed: {
    ...mapGetters(["userList"]),
    filUser: function () {
      const tmpUser = this.$store.state.userList.filter((item) => {
        return item.isPut === 0;
      });
      return tmpUser;
    },
    filFields: function () {
      return this.showField.filter(function (item) {
        return item.autoFields !== 1;
      });
    },
    filChildrenFieds() {
      return function (value) {
        return value.filter(function (item) {
          return item.autoFields === 0;
        });
      };
    },
    // 排序处理
    sortableType() {
      return function (value) {
        if (value.autoFields === 0) {
          return false;
        } else if (value.autoFields === 2 || value.autoFields === 4) {
          return true;
        } else if (value.autoFields === 1 || value.autoFields === 3) {
          return "custom";
        }
      };
    },
    // 文字对齐处理
    filAlign() {
      return function (value) {
        switch (value.fontAlign) {
          case 0:
            return "left";
          case 1:
            return "center";
          case 2:
            return "right";
          default:
            return "center";
        }
      };
    },
  },
  watch: {
    lastParams: function () {
      this.getData("drawer");
    },
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        // 报错处理
        if (value !== undefined) {
          return formatThousandsDots(value.toString());
        }
        // return formatThousandsDots(value.toString());
      } else {
        return value;
      }
    },
  },
  data() {
    return {
      params: {},
      bookList: null,
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      selectAllFields: false,
      dataList: [],
      totalData: [],
      loading: false,
      sortName: null,
      sortDesc: null,
      showRecharge: false,
      yesterdayOptions,
      labelList,
      sendField: [],
      showField: [],
      tmpShowField: [],
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
      pickerOptions,
      platformList,
      extraHeader: [],
      spanArr: [],
      page: 1,
      total: 0,
      pageSize: 1,
    };
  },
  methods: {
    getSpanArr(data) {
      // spanArr和pos需要定义
      this.spanArr = [];
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          // 判断当前元素与上一个元素是否相同 (type要根据要合并的元素更改)
          if (data[i].adminUserId === data[i - 1].adminUserId) {
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    // 合并用户列
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          // [0,0] 表示这一行不显示， [2,1]表示行的合并数
          rowspan: this.dataList.length,
          colspan: _col,
        };
      }
    },
    // 表格颜色处理
    tableRowClassName({ rowIndex }) {
      if (this.spanArr[rowIndex] !== 0 && rowIndex !== 0) {
        return "objectSpanMethod";
      }
      return "";
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    // 组件字段变化
    // handleFieldChange (value) {
    //   if (value.length === 0) {
    //     this.showField = this.originFeild
    //   } else {
    //     this.showField = value
    //   }
    //   this.$nextTick(() => {
    //     this.$refs.userConsumeTable.doLayout()
    //   })
    // },
    // 获取书籍列表
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 获取书籍列表by id
    getBookById(booksIds) {
      getBookList({
        page: 1,
        pageSize: 50,
        booksIds,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 排序处理
    handleSortChange({ column, prop, order }) {
      if (column.sortable !== "custom") {
        return;
      }
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.params.sortName = this.sortName;
      this.params.sortDesc = this.sortDesc;
      this.handlePageChange(1);
    },
    // 重置操作
    handleReset() {
      this.page = 0;
      this.params.originPlatform = null;
      this.params.accountChannelId = null;
      this.params.adminUserId = null;
      this.params.ruledOutAccountChannelIds = [];
      this.sortName = null;
      this.sortDesc = null;
      this.sendField = [];
      this.showField = this.originFeild;
      this.tmpShowField = [];
      this.handlePageChange(1);
    },
    // 分页操作
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    // 选择字段变化
    handleFieldChange(send, show) {
      // if (value.length === 0) {
      //   this.showField = this.originFeild;
      // } else {
      //   this.showField = value;
      // }
      // 请求格式field
      this.sendField = send;
      // 展示格式field
      this.tmpShowField = show;
      this.$nextTick(() => {
        this.$refs.userConsumeTable.doLayout();
      });
    },
    // 请求前处理字段
    handleFields(item) {
      if (item.length === 0) {
        return null;
      }
      let tmpSend = [];
      const _that = this;
      tmpSend = item.reduce(function (a, b) {
        return a.concat(b);
      });
      // 判断勾选项 推入其下所有子级
      for (let i = 0; i < tmpSend.length; i++) {
        const flagIndex = _that.originFeild.findIndex((item2) => {
          return item2.fieldCode === tmpSend[i];
        });
        if (flagIndex !== -1 && "children" in _that.originFeild[flagIndex]) {
          _that.originFeild[flagIndex].children.forEach((item3) => {
            tmpSend.push(item3.fieldCode);
          });
        }
      }
      // 去重
      tmpSend = Array.from(new Set(tmpSend));
      return tmpSend.join(",");
    },
    // 广告数据获取
    getData(flag) {
      // const tody = getRecentlyWeek(true)
      this.loading = true;
      let tmpFields = null;
      if (this.sendField.length > 0) {
        tmpFields = this.handleFields(this.sendField);
      } else {
        this.showField = this.originFeild;
      }
      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      if (flag === "created") {
        tmpform = {
          page: this.page,
          pageSize: this.pageSize,
          originPlatform: this.params.originPlatform,
          accountChannelId: this.params.accountChannelId,
          adminUserId: this.params.adminUserId,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds
              : null,
          showFieldCodes: tmpFields,
        };
      } else if (flag === "drawer") {
        if (this.lastParams.booksId) {
          this.getBookById(this.lastParams.booksId);
        }
        tmpform = {
          ...this.lastParams,
        };

        this.params = { ...this.lastParams };
      } else {
        tmpform = {
          page: this.page,
          pageSize: this.pageSize,
          originPlatform: this.params.originPlatform,
          accountChannelId: this.params.accountChannelId,
          adminUserId: this.params.adminUserId,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds.join()
              : null,
          showFieldCodes: tmpFields,
        };
      }
      getOperationStatics(tmpform)
        .then((res) => {
          // 如果用户选择过字段则表格展示用户选择字段
          if (this.tmpShowField.length > 0) {
            this.showField = this.tmpShowField;
          }
          this.dataList = res.list;
          this.extraHeader = res.headers;
          this.total = res.total;
          this.getSpanArr(this.dataList);
          // 如果不是抽屉 缓存参数
          if (flag !== "drawer") {
            this.addRouterHistory({
              path: this.$route.path,
              params: {
                ...tmpform,
                cacheShowField: this.showField,
                cacheSendField: this.sendField,
              },
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // 获取字段
    handleGetFeilds(flag = "created") {
      getFields({ dataType: "operationUserData" }).then((res) => {
        this.originFeild = res;
        const tmpdata = [];
        for (let i = 0; i < res.length; i++) {
          tmpdata.push({
            fieldName: res[i].fieldName,
            fieldCode: res[i].fieldCode,
          });
        }
        this.fieldsData = tmpdata;
        if (this.showField.length === 0) {
          this.showField = res;
        }
        this.selectAllFields = true;
        this.getData(flag);
        // 判断字段所选长度是否为0 是则全选 否则不全选
      });
    },
    // 处理字段
  },
  created() {
    if (this.lastParams) {
      this.handleGetFeilds("drawer");
      return false;
    }
    this.getBook();
    // 缓存获取
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      // 获取缓存展示字段
      this.showField =
        this.$store.state.routerHistory[tmpIndex].params.cacheShowField;
      // 获取缓存发送前格式字段
      this.sendField =
        this.$store.state.routerHistory[tmpIndex].params.cacheSendField;
    }
    // this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
    this.handleGetFeilds();
    // this.getData()
  },
};
</script>

<style lang="scss" scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.consume-table {
  .adId {
    text-decoration: underline;
    cursor: pointer;
  }
  .min-img {
    width: 70px;
    height: 70px;
  }
}
</style>
